import * as React from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { useNavigate } from 'react-router-dom';
import LoadingSpinner from '../../components/Spinner/LoadingSpinner';
import { Button, Checkbox, Heading, Input, InputGroup, InputLeftAddon, InputRightAddon, Tab, TabList, TabPanel, TabPanels, Tabs } from '@chakra-ui/react';

import styles from './Admin.module.css';
import { MailFormatValidator } from '../../lib/validator';
import AdminFeed from '../Feed/AdminFeed';

const Admin: React.FC = () => {
  const [isLoading, setIsLoading] = React.useState(true);
  const [daysValid, setDaysValid] = React.useState<number>(1);
  const [sendEmail, setSendEmail] = React.useState(false);
  const [inviteToken, setInviteToken] = React.useState('');
  const [email, setEmail] = React.useState('');
  const { getAccessTokenSilently } = useAuth0();

  const navigate = useNavigate();

  const checkIsAdmin = async () => {
    const token = await getAccessTokenSilently();

    fetch(`${process.env.REACT_APP_API_URL}user/hasGroup?group=Admin`, {
      method: 'GET',
      mode: 'cors',
      headers: {
        Authorization: `Bearer ${token}`
      }
    }).then(async (res) => {
      if (res.ok) {
        const result = (await res.text()) === 'true';
        if (!result)
        navigate("/");
      }
    }).catch(err => {
      navigate("/");
    })
    .finally(() => {
      setIsLoading(false);
    })
  }

  const createNewInviteToken = async () => {
    if (daysValid < 1)
      return;

    if (sendEmail && (!email || !MailFormatValidator(email)))
      return;

    const token = await getAccessTokenSilently();

    setIsLoading(true);

    let url = `${process.env.REACT_APP_API_URL}token/add?daysValid=${daysValid}`;

    if (sendEmail) {
      url += `&email=${email}`;
    }

    fetch(url, {
      method: 'POST',
      mode: 'cors',
      headers: {
        Authorization: `Bearer ${token}`
      }
    }).then(async (res) => {
      const result = await res.text();
      setInviteToken(result);
    }).finally(() => {
      setIsLoading(false);
    })

  }

  const onEmailChange = (event: React.FormEvent<any> & React.ChangeEvent<HTMLInputElement>) => {
    setEmail(event.target.value);
  }

  const onSendEmailChange = (event: React.FormEvent<any> & React.ChangeEvent<HTMLInputElement>) => {
    setSendEmail(event.target.checked);
  }

  const onDaysValidChange = (event: React.FormEvent<any> & React.ChangeEvent<HTMLInputElement>) => {
    if (!event.target.value)
      return;

    setDaysValid(parseInt(event.target.value));
  }

  React.useEffect(() => {
    checkIsAdmin();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (isLoading)
    return <LoadingSpinner />

  return (
    <Tabs align='center'>
      <TabList>
        <Tab>Invite Token</Tab>
        <Tab>Hidden Posts</Tab>
      </TabList>

      <TabPanels>
        <TabPanel>
          <div className={styles.Tab}>
            <Heading as='h2' size='lg'>Create new Invite Token</Heading>
            <InputGroup size="sm" width='fit-content'>
              <Input type='number' width='6em' textAlign='right' roundedRight="0" value={daysValid} onChange={onDaysValidChange} />
              <InputRightAddon children="Days Valid" />
            </InputGroup>

            <Checkbox isChecked={sendEmail} onChange={onSendEmailChange}>Send invitation mail</Checkbox>

            {sendEmail &&
              <InputGroup size="sm" width='fit-content'>
                <InputLeftAddon children="Mail:" />
                <Input
                  width='20em'
                  isInvalid={email !== '' && !MailFormatValidator(email)}
                  type='email'
                  value={email}
                  onChange={onEmailChange}
                />
              </InputGroup>
            }

            {inviteToken &&
              <p>Your token is: {inviteToken}</p>
            }

            <Button onClick={createNewInviteToken} colorScheme='green'>Create</Button>
          </div>
        </TabPanel>
        <TabPanel>
          <AdminFeed url={`${process.env.REACT_APP_API_URL}post/hidden`} />
        </TabPanel>
      </TabPanels>
    </Tabs>
  );
}

export default Admin;