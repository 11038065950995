import { useAuth0 } from '@auth0/auth0-react';
import { Heading, Stack, Tag, TagLabel } from '@chakra-ui/react';
import * as React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import LoadingSpinner from '../../components/Spinner/LoadingSpinner';
import UserProfileResult from '../../types/UserProfileResult';
import UserFeed from '../Feed/UserFeed';
import NotFound from '../NotFound/NotFound';

import styles from './UserPage.module.css';

const UserPage: React.FC = () => {
  const [user, setUser] = React.useState<UserProfileResult | null>(null);
  const [notFound, setNotFound] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(true);

  let { username } = useParams();
  let navigate = useNavigate();

  const { getAccessTokenSilently } = useAuth0();

  const handleOnTagClick = (tag: string) => {
    navigate('/feed/' + tag.replaceAll("#", ""));
  }


  const loadUser = async (username: string) => {
    setIsLoading(true);
    const token = await getAccessTokenSilently();

    fetch(`${process.env.REACT_APP_API_URL}user/byUsername/${username}`, {
      method: 'GET',
      mode: 'cors',
      headers: {
        Authorization: `Bearer ${token}`
      }
    }).then(async response => {
      if (response.ok)
        setUser(await response.json() as UserProfileResult);
      else if (response.status === 404)
        setNotFound(true);
    }).catch(err => {
      setNotFound(true);
    })
      .finally(() => {
        setIsLoading(false);
      })
  }

  React.useEffect(() => {
    if (username) {
      loadUser(username);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [username])

  if (isLoading)
    return <LoadingSpinner />
  else if (notFound) {
    return <NotFound />
  }
  else if (user !== null)
    return (
      <div className={styles.container}>
        <div className={styles.userHeader}>
          <img alt='profile' src={user.profilePictureUrl} />
          <Heading as='h1'>{user.nickname}</Heading>
        </div>
        <Heading as='h2' fontSize='1.5em'>Most Used Tags</Heading>

        {user.mostUsedTags.length === 0 && <p>Nothing to see here!</p>}
        {user.mostUsedTags.length !== 0 &&
          <Stack spacing={4} isInline flexWrap='wrap' className={styles.tags}>
            {user.mostUsedTags.map(tag => {
              return (
                <Tag
                  size='md'
                  key={tag}
                  rounded='full'
                  variant="solid"
                  colorScheme="cyan"
                  margin='0.4em'
                  _hover={{
                    cursor: 'pointer'
                  }}
                  onClick={() => handleOnTagClick(tag)}
                >
                  <TagLabel>{tag}</TagLabel>
                </Tag>
              )
            })}
          </Stack>
        }

        <Heading as='h2' fontSize='1.5em' marginTop='1em'>Post History</Heading>
        <UserFeed userId={user.id} />
      </div>
    );
  else
    return <NotFound />
}

export default UserPage;