import { useAuth0 } from "@auth0/auth0-react";
import React from "react";
import { Outlet, Navigate } from "react-router-dom";
import LoadingSpinner from "../components/Spinner/LoadingSpinner";

const PrivateRoute = () => {
  const { isLoading, isAuthenticated } = useAuth0()


  if (isLoading)
    return <LoadingSpinner />;

  // If authorized, return an outlet that will render child elements
  // If not, return element that will navigate to login page
  return isAuthenticated ? <Outlet /> : <Navigate to="/" />;
}

export default PrivateRoute;

