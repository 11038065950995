import * as React from 'react';

import { useAuth0 } from "@auth0/auth0-react";

import styles from './Header.module.css';
import { Avatar, Button, Image, Link, MenuGroup } from '@chakra-ui/react';
import {
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  MenuDivider,
} from "@chakra-ui/react"
import { TriangleDownIcon } from '@chakra-ui/icons'
import { useNavigate } from 'react-router-dom';

import logo from '../../images/Logo.svg';

const Header: React.FC = () => {
  const [isAdmin, setIsAdmin] = React.useState(false);
  const { isAuthenticated, loginWithRedirect, logout, getAccessTokenSilently, user } = useAuth0();
  const navigate = useNavigate();

  const checkIsAdmin = async () => {
    const token = await getAccessTokenSilently();

    fetch(`${process.env.REACT_APP_API_URL}user/hasGroup?group=Admin`, {
      method: 'GET',
      mode: 'cors',
      headers: {
        Authorization: `Bearer ${token}`
      }
    }).then(async (res) => {
      if (res.ok) {
        const result = (await res.text()) === 'true';
        setIsAdmin(result);
      }
    });
  }

  const goToProfile = React.useCallback(() => {
    if (!user)
      return;
    navigate('/user/' + user?.nickname)
  }, [user, navigate])

  React.useEffect(() => {
    if (isAuthenticated)
      checkIsAdmin();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated])

  return (
    <header className={styles.container}>
      <Image h='3.8em' src={logo} className={styles.title} onClick={() => isAuthenticated ? navigate('/feed/new') : navigate('/')} />

      {isAuthenticated && (
        <>
          <Link className={styles.link} color='#fff' onClick={() => navigate('/feed/new')}>New</Link>
          {/* <Link className={styles.link} color='#fff' onClick={() => history.push('/feed/hot')}>Hot</Link> */}
          <Link className={styles.link} color='#fff' onClick={() => navigate('/feed/top')}>Top</Link>
        </>
      )}

      <div className={styles.actions}>
        {!isAuthenticated && (<>
          <Button colorScheme='gray' onClick={() => loginWithRedirect()}>
            Log in
          </Button>

          <Button colorScheme='green' onClick={() => navigate('/signup')}>
            Sign Up
          </Button>
        </>
        )}

        {isAuthenticated && (<>
          <Button colorScheme='green' onClick={() => navigate('/upload')}>Upload</Button>

          <Menu>
            <MenuButton as="button">
              {user && <Avatar name={user.nickname} src={user.picture} />}
              {!user && <TriangleDownIcon color='#ffeb38' w='1.5em' />}
            </MenuButton>
            <MenuList>
              <MenuGroup title="Profile">
                <MenuItem onClick={goToProfile}>
                  My Profile
                </MenuItem>
                <MenuItem onClick={() => navigate('/settings')}>
                  Settings
                </MenuItem>
                {isAdmin &&
                  <>
                    <MenuDivider />
                    <MenuItem onClick={() => navigate('/admin')}>
                      Admin
                    </MenuItem>
                  </>
                }
              </MenuGroup>

              <MenuDivider />

              <MenuItem onClick={() => {
                logout();
                navigate('/');
              }}>
                Log out
              </MenuItem>
            </MenuList>
          </Menu>
        </>
        )}

      </div>
    </header>
  );
}

export default Header;