import * as React from 'react';
import { Routes, Route } from 'react-router-dom';
import UserSettings from '../containers/UserSettings/UserSettings';
import Upload from '../containers/Upload/Upload';
import SignUp from '../containers/SignUp/SignUp';
import Feed from '../containers/Feed/Feed';
import SignUpToken from '../containers/SignUp/SignUpToken/SignUpToken';
import InvalidToken from '../containers/SignUp/InvalidToken/InvalidToken';
import PrivateRoute from './PrivateRoute';
import Post from '../containers/Post/Post';
import Admin from '../containers/Admin/Admin';
import Homepage from '../containers/Homepage/Homepage';
import UserPage from '../containers/UserPage/UserPage';

const CustomSwitch: React.FC = () => {
  return (
    <Routes>
      <Route element={<PrivateRoute />} path='/feed/:tag'>
        <Route path='/feed/:tag' element={<Feed />} />
      </Route>
      <Route element={<PrivateRoute />} path='/settings'>
        <Route path='/settings' element={<UserSettings />} />
      </Route>
      <Route element={<PrivateRoute />} path='/upload'>
        <Route path='/upload' element={<Upload />} />
      </Route>
      <Route element={<PrivateRoute />} path='/user/:username'>
        <Route path='/user/:username' element={<UserPage />} />
      </Route>
      <Route element={<PrivateRoute />} path='/admin'>
        <Route path='/admin' element={<Admin />} />
      </Route>
      <Route element={<InvalidToken />} path='/signup/invalid' />
      <Route element={<SignUp />} path='/signup/:token' />
      <Route element={<SignUpToken />} path='/signup' />
      <Route element={<Post />} path='/post/:id' />
      <Route element={<Homepage />} path='/' />
    </Routes>
  );
}

export default CustomSwitch;