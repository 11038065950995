import * as React from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { Input, Button, Tabs, TabList, Tab, TabPanels, TabPanel, Heading, Stack, Tag, TagLabel, TagCloseButton, Text } from '@chakra-ui/react';
import { useDropzone } from 'react-dropzone';

import styles from './Upload.module.css';
import { useNavigate  } from 'react-router-dom';
import { AttachmentIcon, DeleteIcon } from '@chakra-ui/icons';

const Upload: React.FC = () => {
  const [file, setFile] = React.useState<any>();
  const [imageLink, setImageLink] = React.useState('');
  const [title, setTitle] = React.useState('');
  const [addTagText, setAddTagText] = React.useState('');
  const [tags, setTags] = React.useState(new Array<string>());
  const { getAccessTokenSilently } = useAuth0();

  const navigate = useNavigate();

  const onDrop = React.useCallback(acceptedFiles => {
    setFile(acceptedFiles[0]);
  }, [])

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  const saveImage = async () => {
    try {
      const postData = new FormData()
      postData.append('image', file);
      postData.append('title', title);
      postData.append('tags', tags.join(',').replaceAll("#", ""));

      const token = await getAccessTokenSilently();

      fetch(process.env.REACT_APP_API_URL + 'post/add', {
        body: postData,
        method: 'POST',
        mode: 'cors',
        headers: {
          Authorization: `Bearer ${token}`
        }
      }).then(async (res) => {
        navigate("/post/" + await res.text())
      });
    } catch (error) {
      console.error(error);
    }
  };

  const saveImageByUrl = async () => {
    try {
      const postData = new FormData()
      postData.append('url', imageLink);
      postData.append('title', title);
      postData.append('tags', tags.join(','));

      const token = await getAccessTokenSilently();

      fetch(process.env.REACT_APP_API_URL + 'post/addByUrl', {
        body: postData,
        method: 'POST',
        mode: 'cors',
        headers: {
          Authorization: `Bearer ${token}`
        }
      }).then(async (res) => {
        navigate("/post/" + await res.text())
      });
    } catch (error) {
      console.error(error);
    }
  };

  const handleFileChange = (event: React.FormEvent<any> & React.ChangeEvent<HTMLInputElement>) => {
    setFile(event.target.files![0]);
  }

  const handleImageLinkChange = (event: React.FormEvent<any> & React.ChangeEvent<HTMLInputElement>) => {
    setImageLink(event.target.value);
  }

  const handleTitleChange = (event: React.FormEvent<any> & React.ChangeEvent<HTMLInputElement>) => {
    setTitle(event.target.value);
  }

  const handleAddTagTextChange = (event: React.FormEvent<any> & React.ChangeEvent<HTMLInputElement>) => {
    if ((event.target.value ?? '').includes(' ') || (event.target.value ?? '').includes('#'))
      return;

    setAddTagText(event.target.value);
  }

  const handleAddTag = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    const completeTag = '#' + addTagText;

    if (addTagText && !tags.some(m => m === completeTag)) {
      setTags([...tags, completeTag]);
      setAddTagText('');
    }
  }

  const handleRemoveTag = (tag: string) => {
    if (tags.some(m => m === tag)) {
      const indexOfTag = tags.indexOf(tag);
      tags.splice(indexOfTag, 1)
      setTags([...tags]);
    }
  }

  const handlePaste = (event: any) => {
    const file = (event as ClipboardEvent)?.clipboardData?.files[0];

    if(file != null)
      setFile(file);
  }

  /*
  const handleShareTo = (event: MessageEvent) => {
    const imageBlob = event.data.file;
    setFile(new File(imageBlob, "post", {
      lastModified: Date.now()
    }));
  }

  React.useEffect(() => {
    window.addEventListener("message", handleShareTo, false);

    return () => {
      window.removeEventListener("message", handleShareTo, false);
    }
  })
  */

  return (
    <div className={styles.upload} onPaste={handlePaste}>
      <Heading as='h1'>Create your Post</Heading>
      <form className={styles.form}>
        <Tabs className={styles.tabs} align='start'>
          <TabList>
            <Tab>Datei</Tab>
            <Tab>Link</Tab>
          </TabList>

          <TabPanels>
            <TabPanel className={styles.panel}>
              <div>
                {file ?
                  <div>
                    <div style={{ height: "25em", width: "20em" }}>
                      <img alt='preview' className={styles.previewImage} src={URL.createObjectURL(file)} />
                    </div>
                    <Button className={styles.previewImageClearButton} colorScheme='red' leftIcon={<DeleteIcon />} onClick={() => setFile(null)}>Clear</Button>
                  </div>
                  :
                  <div {...getRootProps()} className={styles.uploadInput}>
                    <input {...getInputProps()} onChange={handleFileChange} accept='image/*' />
                    <AttachmentIcon w="64px" className={styles.uploadInputIcon} />
                    {
                      isDragActive ?
                        <p className={styles.uploadInputText}>Drop the file here...</p> :
                        <p className={styles.uploadInputText}>Drag 'n' drop a file here, or click to select a file</p>
                    }
                  </div>
                }
              </div>

              <div className={styles.rightSide}>
                <Heading as='h3' size='md'>Title</Heading>
                <Input type='text' placeholder='Title' value={title} onChange={handleTitleChange}></Input>
                <br />

                <Heading as='h3' size='md'>Tags</Heading>
                <Stack minHeight='2.5em' border='1px solid #eee' spacing={4} isInline flexWrap='wrap'>
                  {tags.length > 0 ? tags.map(tag => (
                    <Tag
                      size='md'
                      key={tag}
                      rounded="full"
                      variant="outline"
                      colorScheme="cyan"
                      margin='0.25em'
                    >
                      <TagLabel>{tag}</TagLabel>
                      <TagCloseButton onClick={() => handleRemoveTag(tag)} />
                    </Tag>
                  ))
                    :
                    <Text color='#ccc' margin='0.5em'>No tags yet</Text>}
                </Stack>
                <form onSubmit={handleAddTag}>
                  <Input type='text' placeholder='Type your tags here...' value={addTagText} onChange={handleAddTagTextChange}></Input>
                  <Button isDisabled={!addTagText || tags.some(m => m === addTagText)} type='submit'>Add Tag</Button>
                </form>
                <br />
                <Button disabled={!file} marginTop='1em' colorScheme='green' onClick={saveImage}>Submit</Button>
              </div>
            </TabPanel>
            <TabPanel className={styles.panel}>
              <div>
                <div>
                  <div style={{ height: "25em", width: "20em" }}>
                    {imageLink ?
                      <img alt='preview' className={styles.previewImage} src={imageLink} />
                      :
                      <div className={styles.uploadInput}>
                        <p className={styles.uploadInputText}>Insert the link to your image into the input box.</p>
                      </div>
                    }
                  </div>
                  <Button className={styles.previewImageClearButton} colorScheme='red' leftIcon={<DeleteIcon />} onClick={() => setImageLink('')}>Clear</Button>
                </div>
              </div>

              <div className={styles.rightSide}>
                <Heading as='h3' size='md'>Image Link</Heading>
                <Input type='text' isInvalid={!imageLink} placeholder='Image Link' value={imageLink} onChange={handleImageLinkChange}></Input>
                <br />

                <Heading as='h3' size='md'>Title</Heading>
                <Input type='text' placeholder='Title' value={title} onChange={handleTitleChange}></Input>
                <br />

                <Heading as='h3' size='md'>Tags</Heading>
                <Stack minHeight='2.5em' border='1px solid #eee' spacing={4} isInline flexWrap='wrap'>
                  {tags.length > 0 ? tags.map(tag => (
                    <Tag
                      size='md'
                      key={tag}
                      rounded="full"
                      variant="outline"
                      colorScheme="cyan"
                      margin='0.25em'
                    >
                      <TagLabel>{tag}</TagLabel>
                      <TagCloseButton onClick={() => handleRemoveTag(tag)} />
                    </Tag>
                  ))
                    :
                    <Text color='#ccc' margin='0.5em'>No tags yet</Text>}
                </Stack>
                <form onSubmit={handleAddTag}>
                  <Input type='text' placeholder='Type your tags here...' value={addTagText} onChange={handleAddTagTextChange}></Input>
                  <Button isDisabled={!addTagText || tags.some(m => m === addTagText)} type='submit'>Add Tag</Button>
                </form>
                <br />
                <Button disabled={!imageLink} colorScheme='green' onClick={saveImageByUrl}>Submit</Button>
              </div>
            </TabPanel>
          </TabPanels>
        </Tabs>
      </form>
    </div >
  );
}

export default Upload;