import * as React from 'react';
import Post from '../../../types/Post';

import { Button, Heading, IconButton, Image as ChakraImage, Skeleton, Stack, Tag, TagLabel, useToast } from '@chakra-ui/react';

import styles from './FeedPost.module.css';
import { Link, useNavigate } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import HasUserVotedResult from '../../../types/HasUserVotedResult';
import { DeleteIcon, ViewOffIcon, ArrowUpIcon, ArrowDownIcon } from '@chakra-ui/icons';
import { MdShare } from 'react-icons/md';
import * as MimeTypeHelper from '../../../helper/MimeTypeHelper';
import { timeSince } from '../../../helper/DateTimeHelper';

export interface FeedPostProps {
  post: Post,
  setPost: (post: Post) => void;
  onPostDelete: (id: number) => void;
  onPostHide: (id: number) => void;
  isAdmin: boolean;
  isOwnPost?: boolean;
}

const FeedPost: React.FC<FeedPostProps> = (props) => {
  const [isVotingLoading, setIsVotingLoading] = React.useState(false);
  const [userHasUpvoted, setUserHasUpvoted] = React.useState(false);
  const [userHasDownvoted, setUserHasDownvoted] = React.useState(false);
  const [tags, setTags] = React.useState<string[]>([]);

  const toast = useToast();

  const { getAccessTokenSilently } = useAuth0();
  const navigate = useNavigate();

  const resetVote = async (wasUpvoted: boolean) => {
    setIsVotingLoading(true);
    const token = await getAccessTokenSilently();

    fetch(`${process.env.REACT_APP_API_URL}vote/${props.post.id}/reset`, {
      method: 'POST',
      mode: 'cors',
      headers: {
        Authorization: `Bearer ${token}`
      }
    }).then(async (res) => {
      if (res.ok) {
        let newPost = { ...props.post! };
        if (wasUpvoted) {
          newPost.upvoteCount -= 1;
        } else {
          newPost.downvoteCount -= 1;
        }

        props.setPost(newPost);
        setUserHasUpvoted(false);
        setUserHasDownvoted(false);
      }
    }).catch(err => {
      console.log(err);
    }).finally(() => {
      setIsVotingLoading(false);
    });
  }

  const upvote = async () => {
    if (userHasUpvoted) {
      resetVote(true);
      return;
    }

    setIsVotingLoading(true);
    const token = await getAccessTokenSilently();

    fetch(`${process.env.REACT_APP_API_URL}vote/${props.post.id}/up`, {
      method: 'POST',
      mode: 'cors',
      headers: {
        Authorization: `Bearer ${token}`
      }
    }).then(async (res) => {
      if (res.ok) {
        let newPost = { ...props.post!, upvoteCount: props.post!.upvoteCount + 1 };
        if (userHasDownvoted) {
          newPost.downvoteCount -= 1;
        }

        props.setPost(newPost);
        setUserHasUpvoted(true);
        setUserHasDownvoted(false);
      }
    }).catch(err => {
      console.log(err);
    }).finally(() => {
      setIsVotingLoading(false);
    });
  }

  const downvote = async () => {
    if (userHasDownvoted) {
      resetVote(false);
      return;
    }

    setIsVotingLoading(true);
    const token = await getAccessTokenSilently();

    fetch(`${process.env.REACT_APP_API_URL}vote/${props.post.id}/down`, {
      method: 'POST',
      mode: 'cors',
      headers: {
        Authorization: `Bearer ${token}`
      }
    }).then(async (res) => {
      if (res.ok) {
        let newPost = { ...props.post!, downvoteCount: props.post!.downvoteCount + 1 };
        if (userHasUpvoted) {
          newPost.upvoteCount -= 1;
        }

        props.setPost(newPost);
        setUserHasUpvoted(false);
        setUserHasDownvoted(true);
      }
    }).finally(() => {
      setIsVotingLoading(false);
    });
  }

  const share = async () => {
    if (!props.post.imageUrl)
      return;

    getBase64FromImageUrl(props.post.imageUrl);
  }

  const blobToFile = (blob: Blob, fileName: string): File => {
    const file = new File([blob], fileName, {
      type: blob.type,
      lastModified: Date.now()
    })

    return file;
  }

  const b64toBlob = (b64Data: string, contentType: string = '', sliceSize: number = 512) => {
    const byteCharacters = atob(b64Data);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);

      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }

    const blob = new Blob(byteArrays, { type: contentType });
    return blob;
  }

  function getBase64FromImageUrl(url: string) {
    const img = new Image();

    img.setAttribute('crossOrigin', 'anonymous');

    img.onload = function () {
      const canvas = document.createElement("canvas");
      canvas.width = img.width;
      canvas.height = img.height;

      const ctx = canvas.getContext("2d")!;
      ctx.drawImage(img, 0, 0);

      var path_splitted = props.post.imageUrl.split('.');
      var extension = path_splitted.pop();

      let dataURL = canvas.toDataURL("image/png");

      dataURL = dataURL.replace(/^data:image\/(png|jpg);base64,/, "");

      const blob = b64toBlob(dataURL, MimeTypeHelper.getMimeType(extension!));

      const file = blobToFile(blob, `post${MimeTypeHelper.getFileExtension(blob.type)}`);

      try {
        navigator.share({
          title: props.post.title,
          files: [file]
        } as any);
      } catch (error) {
        //ignore
      }
    };

    img.src = url;
  }

  const handleOnTagClick = (tag: string) => {
    navigate('/feed/' + tag.replaceAll("#", ""));
  }

  const handlePostClick = () => {
    navigate('/post/' + props.post.id);
  }

  const loadHasUserVoted = async () => {
    setIsVotingLoading(true);
    const token = await getAccessTokenSilently();

    fetch(`${process.env.REACT_APP_API_URL}vote/${props.post.id}/userHasVoted`, {
      method: 'GET',
      mode: 'cors',
      headers: {
        Authorization: `Bearer ${token}`
      }
    }).then(async (res) => {
      const result = await res.json() as HasUserVotedResult;

      setUserHasUpvoted(result.userHasUpvoted);
      setUserHasDownvoted(result.userHasDownvoted);
    }).catch(err => {
      console.log(err);
    }).finally(() => {
      setIsVotingLoading(false);
    });
  }

  const loadTags = async () => {
    const token = await getAccessTokenSilently();

    fetch(`${process.env.REACT_APP_API_URL}post/${props.post.id}/tags`, {
      method: 'GET',
      mode: 'cors',
      headers: {
        Authorization: `Bearer ${token}`
      }
    }).then(async (res) => {
      const result = await res.json() as string[];

      setTags(result)
    }).catch(err => {
      console.log(err);
    });
  }

  const getTime = (dateString: string) => {
    let posted = new Date(Date.parse(dateString));
    return timeSince(posted);
  }

  const copyToClipboard = () => {
    navigator.clipboard.writeText(props.post.imageUrl).then(() => {
      toast({
        title: 'URL copied',
        description: 'Copied the post URL to your clipboard!',
        status: 'info',
        isClosable: true
      });
    })
  }

  React.useEffect(() => {
    loadHasUserVoted();
    loadTags();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div className={styles.FeedPost}>
      <Heading as='h3' size='lg' marginBottom="5px" onClick={handlePostClick}>{props.post.title}</Heading>

      <ChakraImage fallback={<Skeleton className={styles.Skeleton} />} onClick={handlePostClick} className={styles.PostImage} alt='post' src={props.post.imageUrl} crossOrigin="anonymous" />

      {props.post.username &&
        <div style={{ fontSize: '0.8em', marginTop: '5px', display: 'flex', flexDirection: 'row' }}>
          <p>Posted by</p>
          <Link style={{ color: '#0000EE', marginRight: '0.4em', marginLeft: '0.4em' }} to={`/user/${props.post.username}`}>{props.post.username}</Link>
          <p>{getTime(props.post.createdAt)} ago</p>
        </div>
      }

      <div className={styles.Buttons}>
        <Button isLoading={isVotingLoading} borderColor={userHasUpvoted ? 'black' : 'transparent'} leftIcon={<ArrowUpIcon />} colorScheme='blue' onClick={upvote}>{props.post?.upvoteCount ?? 0}</Button>
        <Button isLoading={isVotingLoading} borderColor={userHasDownvoted ? 'black' : 'transparent'} leftIcon={<ArrowDownIcon />} colorScheme='orange' onClick={downvote}>{props.post?.downvoteCount ?? 0}</Button>
        {navigator.share !== undefined &&
          <IconButton aria-label='share' icon={<MdShare />} onClick={share} />
        }
        {navigator.share === undefined &&
          <IconButton aria-label='share' icon={<MdShare />} onClick={copyToClipboard} />
        }
        {props.isAdmin &&
          <IconButton aria-label='hide' icon={<ViewOffIcon />} onClick={() => props.onPostHide(props.post.id)} />
        }

        {(props.isAdmin || props.isOwnPost) &&
          <IconButton aria-label='hide' icon={<DeleteIcon />} colorScheme='red' onClick={() => props.onPostDelete(props.post.id)} />
        }
      </div>

      <Stack minHeight='2.5em' marginTop='0.5em' spacing={4} isInline flexWrap='wrap'>
        {tags.map(tag => (
          <Tag
            size='lg'
            key={tag}
            rounded="full"
            variant="solid"
            colorScheme="cyan"
            margin='0.25em'
            _hover={{
              cursor: 'pointer'
            }}
            onClick={() => handleOnTagClick(tag)}
          >
            <TagLabel>{tag}</TagLabel>
          </Tag>
        ))}
      </Stack>
    </div>
  );
}

export default FeedPost;