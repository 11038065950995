import * as React from 'react';

import { theme, ChakraProvider } from '@chakra-ui/react';
import Layout from '../Layout/Layout';
import { useNavigate } from 'react-router-dom';
import { Auth0Provider } from '@auth0/auth0-react';

const App: React.FC = () => {
  const naviagte = useNavigate();

  const onRedirectCallback = (appState: any) => {
    naviagte(appState?.returnTo || window.location.pathname);
  };

  return (
    <Auth0Provider
      domain={process.env.REACT_APP_AUTH0_DOMAIN!}
      clientId={process.env.REACT_APP_AUTH0_CLIENT_ID!}
      audience={process.env.REACT_APP_AUTH0_AUDIENCE!}
      redirectUri={window.location.origin}
      onRedirectCallback={onRedirectCallback}
    >
      <ChakraProvider theme={theme}>
        <Layout />
      </ChakraProvider>
    </Auth0Provider>
  );
}

export default App;
