export const timeSince = (date: Date) => {
  const now = new Date();

  const seconds = Math.floor((now.getTime() - date.getTime()) / 1000);

  let interval = seconds / 31536000;

  if (interval > 1) {
    const returnInterval = Math.floor(interval)
    return returnInterval + " year" + (returnInterval > 1 ? "s" : "");
  }
  interval = seconds / 2592000;
  if (interval > 1) {
    const returnInterval = Math.floor(interval)
    return returnInterval + " month" + (returnInterval > 1 ? "s" : "");
  }
  interval = seconds / 86400;
  if (interval > 1) {
    const returnInterval = Math.floor(interval)
    return returnInterval + " day" + (returnInterval > 1 ? "s" : "");
  }
  interval = seconds / 3600;
  if (interval > 1) {
    const returnInterval = Math.floor(interval)
    return returnInterval + " hour" + (returnInterval > 1 ? "s" : "");
  }
  interval = seconds / 60;
  if (interval > 1) {
    const returnInterval = Math.floor(interval)
    return returnInterval + " minute" + (returnInterval > 1 ? "s" : "");
  }

  return "seconds";
}