import * as React from 'react';
import Header from '../Header/Header';
import CustomSwitch from '../../hoc/CustomSwitch';
import MobileHeader from '../MobileHeader/MobileHeader';
import { useAuth0 } from '@auth0/auth0-react';
import LoadingSpinner from '../Spinner/LoadingSpinner';

const Layout: React.FC = () => {
  const [mobile, setMobile] = React.useState(false);

  const { isLoading } = useAuth0();

  const onResize = () => {
    if (window!.innerWidth > 900) {
      setMobile(false);
    }
    else {
      setMobile(true);
    }
  }

  React.useEffect(() => {
    window.addEventListener("resize", onResize);
    onResize();
    return () => {
      window.removeEventListener("resize", onResize);
    }
  }, []);

  return isLoading ? <LoadingSpinner /> : (
    <div style={{paddingTop: mobile ? "3em" : "4em"}}>
      {mobile ? <MobileHeader /> : <Header/>}
      <CustomSwitch />
    </div>
  );
}

export default Layout;